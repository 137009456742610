import { Button, Form, Table } from 'antd';
import { ColumnType } from 'antd/es/table';
import React, { useState } from 'react';
import { getOperationList } from './api';
import useTable from '@/hooks/useTable';
import DiffChangeModal from './DiffChangeModal';
import SearchForm from './SearchForm';
import AuthPageContainer from '../AuthPageContainer';
import { projectInfoHouseholdRole } from '@/utils/userRoleMap';

interface OperationLogProps {
  projectId: string;
  archiveId?: string;
  showSearch?: boolean;
  showBtn?: boolean;
}

export default (props: OperationLogProps) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [detailInfo, setDetailInfo] = useState<any>({});

  const { projectId, archiveId, showSearch = false, showBtn = false } = props;

  const [form] = Form.useForm();

  const fetchData = async (
    pagination: { pageNo: number; pageSize: number },
    resetPageNo?: boolean,
  ) => {
    const values = form.getFieldsValue();
    let startTime, endTime;
    if (values.operTime && values.operTime.length) {
      startTime = values.operTime[0]?.format('YYYY-MM-DD HH:mm:ss');
      endTime = values.operTime[1]?.format('YYYY-MM-DD HH:mm:ss');
    }
    return await getOperationList({
      params: {
        pageNum: resetPageNo ? 1 : pagination.pageNo,
        pageSize: pagination.pageSize,
        projectId,
        title: values.title,
        operName: values.operName,
        startTime,
        endTime,
        hhdArchivesId: archiveId,
      },
    });
  };
  const { handleFetchData, selectedRows, ...restTableProps } = useTable({
    fetchData,
  });

  const columns: Array<ColumnType<any>> = [
    {
      title: '时间',
      dataIndex: 'operTime',
      key: 'operTime',
    },
    {
      title: '操作人',
      dataIndex: 'operName',
      key: 'operName',
      render(text, record) {
        if (record.operNickName) {
          return `${text}(${record.operNickName})`;
        } else {
          return text;
        }
      },
    },
    {
      title: '事件',
      dataIndex: 'title',
      key: 'title',
      render(text, record) {
        if (record.beforeHhdArchivesId) {
          if (!archiveId) {
            return text;
          }
          if (record.beforeHhdArchivesId === archiveId) {
            return `${text}(转出)`;
          } else {
            return `${text}(转入)`;
          }
        } else {
          return text;
        }
      },
    },
    {
      title: '主机IP',
      dataIndex: 'operIp',
      key: 'operIp',
    },
  ];

  if (showBtn) {
    columns.push({
      title: '内容',
      dataIndex: 'content',
      key: 'content',
      width: 160,
      align: 'center',
      render(text, record) {
        return (
          <Button
            type="primary"
            onClick={() => {
              setDetailInfo(record);
              setModalVisible(true);
            }}
          >
            查看
          </Button>
        );
      },
    });
  }

  const doSearch = () => {
    handleFetchData({
      resetPageNo: true,
    });
  };

  return (
    <>
      <AuthPageContainer auth={projectInfoHouseholdRole.opeartionLogs.list}>
        {showSearch && (
          <div style={{ marginBottom: 15 }}>
            <SearchForm form={form} doSearch={doSearch} />
          </div>
        )}

        <Table columns={columns} {...restTableProps} />
        <DiffChangeModal
          modalVisible={modalVisible}
          onCancel={() => setModalVisible(false)}
          detailInfo={detailInfo}
        />
      </AuthPageContainer>
    </>
  );
};
